@font-face {
  font-family: 'hapsuperapp';
  src:  url('fonts/hapsuperapp.eot?63uvmd');
  src:  url('fonts/hapsuperapp.eot?63uvmd#iefix') format('embedded-opentype'),
    url('fonts/hapsuperapp.woff2?63uvmd') format('woff2'),
    url('fonts/hapsuperapp.ttf?63uvmd') format('truetype'),
    url('fonts/hapsuperapp.woff?63uvmd') format('woff'),
    url('fonts/hapsuperapp.svg?63uvmd#hapsuperapp') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hapsuperapp' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-link:before {
  content: "\e94f";
}
.icon-calendar-clock:before {
  content: "\e94e";
}
.icon-account-box:before {
  content: "\e94d";
}
.icon-delete-filled:before {
  content: "\e94c";
}
.icon-delete-scratched:before {
  content: "\e94b";
}
.icon-chevron-bar-right:before {
  content: "\e949";
}
.icon-chevron-bar-left:before {
  content: "\e94a";
}
.icon-plus:before {
  content: "\e948";
}
.icon-right:before {
  content: "\e900";
}
.icon-mail:before {
  content: "\e947";
}
.icon-marked-circle-outline:before {
  content: "\e944";
}
.icon-progress-alert:before {
  content: "\e945";
}
.icon-progress-clock:before {
  content: "\e946";
}
.icon-clipboard-check:before {
  content: "\e943";
}
.icon-money:before {
  content: "\e942";
}
.icon-home-6-line:before {
  content: "\e91e";
}
.icon-clipboard-text-outline:before {
  content: "\e91f";
}
.icon-account-multiple-plus:before {
  content: "\e918";
}
.icon-card-account-details:before {
  content: "\e919";
}
.icon-account-plus:before {
  content: "\e91a";
}
.icon-refresh:before {
  content: "\e91b";
}
.icon-file-arrow-left-right-outline:before {
  content: "\e91c";
}
.icon-swap-horizontal:before {
  content: "\e91d";
}
.icon-file-excel:before {
  content: "\e916";
}
.icon-hapvida-logo:before {
  content: "\e914";
}
.icon-gndi-logo:before {
  content: "\e915";
}
.icon-email-mark-as-unread:before {
  content: "\e913";
}
.icon-camera-2-line:before {
  content: "\e90f";
}
.icon-email-check-outline:before {
  content: "\e910";
}
.icon-phone-fill:before {
  content: "\e911";
}
.icon-whatsapp:before {
  content: "\e912";
}
.icon-export:before {
  content: "\e90e";
}
.icon-emoticon-excited-outline:before {
  content: "\e90d";
}
.icon-copy:before {
  content: "\e90c";
}
.icon-home-work:before {
  content: "\e90b";
}
.icon-eye-off-outline:before {
  content: "\e907";
}
.icon-eye-outline:before {
  content: "\e908";
}
.icon-eye:before {
  content: "\e909";
}
.icon-alert-circle:before {
  content: "\e902";
}
.icon-people:before {
  content: "\e901";
}
.icon-user:before {
  content: "\e903";
}
.icon-camera:before {
  content: "\e922";
}
.icon-building:before {
  content: "\e921";
}
.icon-delete:before {
  content: "\e920";
}
.icon-close:before {
  content: "\e923";
}
.icon-nursery:before {
  content: "\e924";
}
.icon-edit:before {
  content: "\e932";
}
.icon-download:before {
  content: "\e925";
}
.icon-bed:before {
  content: "\e926";
}
.icon-coins:before {
  content: "\e927";
}
.icon-drawer:before {
  content: "\e928";
}
.icon-stethoscope:before {
  content: "\e929";
}
.icon-tooth:before {
  content: "\e92a";
}
.icon-hospital-hapvida:before {
  content: "\e92b";
}
.icon-chevron-up:before {
  content: "\e92c";
}
.icon-chevron-left:before {
  content: "\e92d";
}
.icon-chevron-right:before {
  content: "\e93b";
}
.icon-order-column:before {
  content: "\e93c";
}
.icon-gear:before {
  content: "\e93d";
}
.icon-side-bar-open:before {
  content: "\e92e";
}
.icon-side-bar-close:before {
  content: "\e92f";
}
.icon-clipboard-pulse:before {
  content: "\e930";
}
.icon-timer:before {
  content: "\e931";
}
.icon-calendar:before {
  content: "\e933";
}
.icon-table-view:before {
  content: "\e934";
}
.icon-grid-view:before {
  content: "\e935";
}
.icon-filter:before {
  content: "\e936";
}
.icon-chevron-down:before {
  content: "\e937";
}
.icon-bell:before {
  content: "\e938";
}
.icon-loading:before {
  content: "\e904";
}
.icon-account-multiple:before {
  content: "\e939";
}
.icon-file-check:before {
  content: "\e93a";
}
.icon-home:before {
  content: "\e93e";
}
.icon-file-document:before {
  content: "\e93f";
}
.icon-alert:before {
  content: "\e940";
}
.icon-check:before {
  content: "\e941";
}
.icon-heart:before {
  content: "\e905";
}
.icon-help-circle:before {
  content: "\e906";
}
.icon-face-agent:before {
  content: "\e90a";
}
.icon-magnifier:before {
  content: "\e917";
}
